
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "PublicHeader",
  props: {
    active: String,
  },
  setup() {
    const headerNavList: Array<any> = reactive([
      {
        navName: "Main page",
        navPath: "/",
        active: "home",
      },
      {
        navName: "About us",
        navPath: "/about",
        active: "about",
      },
      {
        navName: "Introduction",
        navPath: "/introduction",
        active: "introduction",
      },
      {
        navName: "Shipping Policy",
        navPath: "/shipping",
        active: "shipping",
      },
      {
        navName: "Terms & Conditions",
        navPath: "/terms",
        active: "terms",
      },
      {
        navName: "Contact us",
        navPath: "/contact",
        active: "contact",
      },
    ]);
    return { headerNavList };
  },
});
