
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "PublicFooter",
  setup() {
    const footerLinkList: Array<any> = reactive([
      {
        linkName: "Main page",
        linkPath: "/",
        active: "home",
      },
      {
        linkName: "About us",
        linkPath: "/about",
        active: "about",
      },
      {
        linkName: "Introduction",
        linkPath: "/introduction",
        active: "introduction",
      },
      {
        linkName: "Shipping Policy",
        linkPath: "/shipping",
        active: "shipping",
      },
      {
        linkName: "Terms & Conditions",
        linkPath: "/terms",
        active: "terms",
      },
      {
        linkName: "Contact us",
        linkPath: "/contact",
        active: "contact",
      },
    ]);
    return { footerLinkList };
  },
});
