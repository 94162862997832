
import { defineComponent } from "vue";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Footer from "@/components/footer.vue"; // @ is an alias to /src

export default defineComponent({
  name: "HomeView",
  components: {
    Header,
    Footer,
  },
});
